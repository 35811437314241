//useResiduals -> takes in modelID. Response would be loading, error, and response object
// at some point strip down response object to what we need
// return useDataRobotAPI and the call
import {getFromDataRobotAPI, useDataRobotAPI} from "./hooks";

export function useProjects({apiToken, catalogId, ...rest}) {
    const url = `projects`;
    return useDataRobotAPI({url, apiToken, ...rest});
}

export function useDatasetsForProject({apiToken, catalogId, ...rest}) {
    const url = `datasets/${catalogId}`;
    return useDataRobotAPI({url, apiToken, ...rest});
}

export function useDatasetFile({apiToken, datasetId, ...rest}) {
    const url = `datasets/${datasetId}/file`;
    return useDataRobotAPI({url, apiToken, ...rest});
}

export function useProjectFeatures({apiToken, projectId, ...rest}) {
    const url = `projects/${projectId}/modelingFeatures`;
    return useDataRobotAPI({url, apiToken, ...rest});
}

export function useModelResiduals({apiToken, projectId, modelId, ...rest}) {
    const url = `projects/${projectId}/models/${modelId}/residuals`;
    return useDataRobotAPI({url, apiToken, ...rest});
}

export function useModelFeatureImpact({apiToken, projectId, modelId, ...rest}) {
    const url = `projects/${projectId}/models/${modelId}/featureImpact`;
    return useDataRobotAPI({url, apiToken, ...rest});
}

export function useModelFeaturePredictionExplanations({apiToken, projectId, modelId, ...rest}) {
    const url = `projects/${projectId}/models/${modelId}/predictionExplanationsInitialization`;
    return useDataRobotAPI({url, apiToken, ...rest});
}

export function useModelLiftChart({apiToken, projectId, modelId, ...rest}) {
    const url = `projects/${projectId}/models/${modelId}/liftChart`;
    return useDataRobotAPI({url, apiToken, ...rest});
}

export function useModelCoefficients({apiToken, projectId, modelId, ...rest}) {
    const url = `projects/${projectId}/models/${modelId}/parameters`;
    //Note: we want to use the parameters property of response here
    return useDataRobotAPI({url, apiToken, ...rest});
}
//useFeatureImpact

export function getProjects({apiToken, catalogId, ...rest}) {
    const url = `projects`;
    return getFromDataRobotAPI({url, apiToken, ...rest});
}

export function getDatasetForProject({apiToken, catalogId, ...rest}) {
    const url = `datasets/${catalogId}`;
    return getFromDataRobotAPI({url, apiToken, ...rest});
}

export function getDatasetFile({apiToken, datasetId, ...rest}) {
    const url = `datasets/${datasetId}/file`;
    return getFromDataRobotAPI({url, apiToken, ...rest});
}

export function getProjectFeatures({apiToken, projectId, ...rest}) {
    const url = `projects/${projectId}/modelingFeatures`;
    return getFromDataRobotAPI({url, apiToken, ...rest});
}

export function getModelResiduals({apiToken, projectId, modelId, ...rest}) {
    const url = `projects/${projectId}/models/${modelId}/residuals`;
    return getFromDataRobotAPI({url, apiToken, ...rest});
}

export function getModelFeatureImpact({apiToken, projectId, modelId, ...rest}) {
    const url = `projects/${projectId}/models/${modelId}/featureImpact`;
    return getFromDataRobotAPI({url, apiToken, ...rest});
}

export function getModelFeaturePredictionExplanations({apiToken, projectId, modelId, ...rest}) {
    const url = `projects/${projectId}/models/${modelId}/predictionExplanationsInitialization`;
    return getFromDataRobotAPI({url, apiToken, ...rest});
}

export function getModelLiftChart({apiToken, projectId, modelId, ...rest}) {
    const url = `projects/${projectId}/models/${modelId}/liftChart`;
    return getFromDataRobotAPI({url, apiToken, ...rest});
}

export function getModels({apiToken, projectId, ...rest}) {
    const url = `projects/${projectId}/models/`;
    return getFromDataRobotAPI({url, apiToken, ...rest});
}

export function getModelCoefficients({apiToken, projectId, modelId, ...rest}) {
    const url = `projects/${projectId}/models/${modelId}/parameters`;
    //Note: we want to get the parameters property of response here
    return getFromDataRobotAPI({url, apiToken, ...rest});
}