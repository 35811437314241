import React from 'react';

import './App.scss';
import {Canvas} from "./Canvas/Canvas";
import {Helmet} from "react-helmet";

function App() {
  return (
    <div className="App">
      <Helmet>
        <script src="./plotly-2.2.0.min.js"/>
      </Helmet>
      <Canvas/>
    </div>
  );
}

export default App;
