import React, {memo, useEffect, useMemo, useRef, useState} from 'react';
import { ResizableBox } from 'react-resizable';
import classnames from "classnames";

import './Node.scss';
import '../Resizable/react-resizable.scss';
import {Handle} from "react-flow-renderer";
import {ReactComponent as RoundedStem} from "../SVG/RoundedStem.svg";
import {ReactComponent as SettingsButtonSVG} from "../SVG/SettingsButton.svg";
import {ReactComponent as SettingsStem} from "../SVG/SettingsStem.svg";
import {ReactComponent as CloseNodeButton} from '../SVG/CloseNodeButton.svg';
import {debounce} from "lodash";
import SearchableList from "../SharedComponents/SearchableList/SearchableList";
import Selectable from "../SharedComponents/Selectable/Selectable";

const plotTypes = [
  {plotType: 'scatter', name: "Scatter", index: 'yaxis'},
  {plotType: 'line', name: "Line", index: 'xaxis'},
  {plotType: 'box', name: "Box", index: 'yaxis'},
  {plotType: 'violin', name: "Violin", index: 'yaxis'},
  {plotType: 'hist', name: "Histogram", index: 'xaxis'},
  {plotType: 'bar', name: "Bar", index: 'xaxis'},
  {plotType: 'table', name: "Table"},
];

const plotTypeIndexLookup = {
    'scatter': 'yaxis',
    'line': 'xaxis',
    'box': 'yaxis',
    'violin': 'yaxis',
    'hist': 'xaxis',
    'bar': 'xaxis',
}

const otherAxis = (axis) => axis === "xaxis" ? "yaxis" : "xaxis";

const defaultLayout = {
  paper_bgcolor: '#292929',
  plot_bgcolor: '#292929',
  yaxis: {
    gridcolor: '#444',
    tickcolor: '#444',
    zerolinecolor: '#444',
  },
  xaxis: {
    gridcolor: '#444',
    tickcolor: '#444',
    zerolinecolor: '#444',
  },
  font: {
    color: '#fff'
  },
  margin: {
    r: 40, t: 40
  },
  showlegend: true,
  autorange: true,
};

const SettingsModal = ({plotType, setPlotType, onClose}) => {
  const [shownOptions, setShownOptions] = useState([]);

  const updateOptions = (newOptions) => {
    setShownOptions(newOptions);
  }
  return (
    <div className={"plot-settings-modal"}>
      <SearchableList items={plotTypes} onSearch={updateOptions} searchKey={"name"} />
      {shownOptions?.length > 0 && shownOptions.map(({name, plotType: p}, i) => (
        <Selectable
          key={`${p}-${i}`}
          selected={plotType === p}
          onSelection={() => setPlotType(p)}
        >{name}</Selectable>
      ))}
      <div className={"plot-settings-close"} onClick={(e) => {
        onClose();
        e.stopPropagation();
      }}>
        <CloseNodeButton/>
      </div>
    </div>
  );
};

const SettingsButton = ({plotType, setPlotType, onActivated}) => {
  const [activated, setActivated] = useState(false);

  const onClick = (e) => {
    setActivated(!activated);
    onActivated(!activated);
  };

  return <div style={{display: "flex"}}>
    <SettingsButtonSVG className={"plot-settings-button"} onClick={onClick}/>
    {activated && <div style={{display: "flex"}}>
      <SettingsStem className={"plot-settings-stem flipped"} />
      <SettingsModal
      onClose={() => {
        setActivated(false);
        onActivated(false);
      }}
      plotType={plotType}
      setPlotType={setPlotType}
      />
    </div>}
  </div>;
};

const PlotNode = (({data, width, height}) => {
  const plotWrapper = useRef(null);
  const plot = useRef(null);
  const df = useRef(data.getDataset());
  const [plotFunction, setPlotFunction] = useState("scatter");
  // const allFeatures = df.current.columns;
  // const rows = df.current.head(1000);
  const [settingsOpen, setSettingsOpen] = useState(false);

  const update = () => {
    updatePlot();
  };

  useEffect(() => {
    data.update = update;
  }, []);

  const withDf = (_df, fn) => {
    fn(_df.current);
  };

  const updatePlot = () => {
    withDf(df, asyncUpdatePlot);
  }

  const asyncUpdatePlot = (_df) => {
    let filteredDf = _df.loc({
      columns: Object.keys(data.featureFilter).filter((a) => data.featureFilter[a])
    });
    filteredDf.reset_index(true);
    const showTable = plotFunction === 'table';
    const showOccurrences = plotFunction === "hist" || filteredDf.columns.length !== 2;
      // || data.primaryFeature == null || !filteredDf.columns.includes(data.primaryFeature)
    if (showTable) {
      filteredDf.plot(data.id)[plotFunction]({
        layout: {
          ...defaultLayout,
          margin: {
            r: 20, t: 20, l: 20, b: 10,
          }
        },
        header_style: {
          line: {color: '#444', width: 1},
          fill: {
            color: ['#292929'],
          },
        },
        cell_style: {
          line: {color: '#444', width: 1},
          fill: {
            color: ['#292929'],
          },
        },
    });
    } else if (showOccurrences) {
      const layout = {...defaultLayout};
      layout.yaxis = {...layout.yaxis, title: "Occurrences"};

      filteredDf.plot(data.id)[plotFunction](
        {layout, columns: filteredDf.columns}
      );
    } else {
      // const primaryFeature = data.primaryFeature;
      const primaryFeature = filteredDf.columns[0];
      const nonPrimaryColumns = filteredDf.columns.filter((c) => c !== primaryFeature);
      if (nonPrimaryColumns.length && primaryFeature) {
        filteredDf.set_index({key: primaryFeature, inplace: true});
        filteredDf.sort_index({inplace: true});
      }
      const axis = plotTypeIndexLookup[plotFunction];
      const layout = {
          ...defaultLayout,
          [axis]: {
            ...defaultLayout[axis],
            title: primaryFeature,
          }
      };
      if (nonPrimaryColumns.length === 1) {
        const other = otherAxis(axis);
        layout[other] = {
          ...defaultLayout[other],
          title: nonPrimaryColumns[0],
        }
      }
      filteredDf.plot(data.id)[plotFunction]({
        columns: nonPrimaryColumns,
        layout,
      });
    }
  }

  useEffect(debounce(updatePlot, 300), [data, width, height, plotFunction])

  function stopEvents(e) {
    const shouldStop = ['.modebar', '.draglayer', '.react-resizable-handle'].some((layerClass) =>
      document.querySelector(layerClass)?.contains(e.target) || e.target.classList.contains(layerClass)
    );
    if (shouldStop) {
      e.stopPropagation();
      e.preventDefault();
      return false;
    }
  }

  useEffect(() => {
    if (plotWrapper.current) {
      plotWrapper.current.removeEventListener('mousedown', stopEvents);
      plotWrapper.current.addEventListener('mousedown', stopEvents);
    }
  }, [plotWrapper.current])

  return (
    <div className={"plot-node-wrapper"}>
      <div id={data.id} ref={plotWrapper} className={classnames("node plot")} style={{width, height}}>
        <div id={`plot-${data.id}`} ref={plot} style={{height: '100%'}} />
        <div className={"plot-handle"}>
          <Handle
            className={"plot-flow-handle"}
            type="target"
            position="left"
          />
          <RoundedStem className={"flat-flow-handle"} />
        </div>
      </div>
      <div className={classnames("plot-settings-handle", {
        "open": settingsOpen
      })}>
        <SettingsStem className={"plot-settings-stem"} />
        <SettingsButton
          plotType={plotFunction}
          setPlotType={setPlotFunction}
          onActivated={(activated) => setSettingsOpen(activated)}
        />
      </div>
    </div>
  )
});

export default ({data}) => {
  const [withPlotState, setWithPlotState] = useState({width: 500, height: 400});

  const onResize = (event, {size}) => {
    setWithPlotState({width: size.width, height: size.height});
    return false;
  };

  const {width, height} = withPlotState;

  return (
    <ResizableBox
      width={width}
      height={height}
      onResize={onResize}
      onResizeStart={(e) => {
        e.stopPropagation();
        e.preventDefault();
        return false;
      }}
      onResizeStop={() => data.setNodesDraggable(true)}>
      <PlotNode data={data} width={width} height={height} />
    </ResizableBox>
  );
}