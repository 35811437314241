import React, {useCallback, useEffect, useRef, useState} from "react";
import {useDropzone} from 'react-dropzone';
import * as Papa from "papaparse";

import './Canvas.scss';
import classnames from "classnames";
import CanvasArea from "./CanvasArea";
import StartFromProject from "../StartFromProject/StartFromProject";

import {ReactComponent as SearchDR} from '../SVG/SearchDR.svg';

function DropArea({onDrop, style, showHelp = true}) {
  const {acceptedFiles, getRootProps, getInputProps} = useDropzone({
    onDrop,
    noDragEventsBubbling: false,
    noKeyboard: true,
    noClick: true,
  });

  return (
    <section className="dropzone-container" style={{...style}}>
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
      </div>
    </section>
  );
}

export function Canvas() {
  const apiToken = useRef(null);
  const [showProjectSearch, setShowProjectSearch] = useState(false);
  const datasets = useRef([]);
  const [datasetCount, setDatasetCount] = useState(0);
  const [showHelp, setShowHelp] = useState(true);
  const dragCounter = useRef(0);

  const onSaveApiToken = (newApiToken) => {
    apiToken.current = newApiToken;
  };

  async function readFiles(files, {x, y}) {
    files.forEach((file) => {
      const reader = new FileReader();
      const newDataset = {x, y: 40, type: "Features", ...file};
      reader.onloadstart = function (e) {
        datasets.current.push(newDataset);
        setDatasetCount(datasets.current.length);
      }
      reader.onload = function(e) {
        newDataset.contents = e.target.result;
        if (newDataset.load) {
          newDataset.load();
        }
      };
      reader.readAsText(file);
    })
  }

  function ingestDataString({dataString, name, type = "Features", x, y}) {
    const newDataset = {
      x: x ?? 20, y: y ?? 80, path: name, contents: dataString, type,
    };
    datasets.current.push(newDataset);
    setDatasetCount(datasets.current.length);
  }

  function onSaveProjectModels({projectId, projectName, models}) {
    const queuedToIngest = [];
    Promise.all(
      models.map((model, i) => model.getFeatureImpact()
        .then((response) => {
          queuedToIngest.push({
            dataString: Papa.unparse(response.data.featureImpacts),
            name: `${model.name}: (${projectName})`,
            type: "Feature Impact",
          });
        }).catch((error) => console.log(error))
      )
      //   .concat(models.map((model, i) => model.getLiftChart().then((response) =>
      //   queuedToIngest.push({
      //     dataString: Papa.unparse(response.data.charts),
      //     name: `${model.name}: (${projectName})`,
      //     type: "Lift Chart",
      //   })
      // ).catch((error) => console.log(error))))
      //   .concat(models.map((model, i) => model.getResiduals().then((response) =>
      //   queuedToIngest.push({
      //     dataString: Papa.unparse(response.data.residuals),
      //     name: `${model.name}: (${projectName})`,
      //     type: "Residuals",
      //   })).catch((error) => console.log(error))
      // ))
      ).catch((error) => console.log(error))
      .then(() => {
        queuedToIngest.forEach((item, i) => {
          ingestDataString({
            ...item,
            x: 20,
            y: (i * 80) + 200
          })
        });
      })
  }

  const onDrop = useCallback((acceptedFiles, _, e) => {
    readFiles(acceptedFiles, {x: e.clientX, y: e.clientY});
    setDataDrag(false);
    dragCounter.current = 0;
    setShowHelp(false);
  }, [])

  const [dataDrag, setDataDrag] = useState(false);

  useEffect(() => {
    document.addEventListener('dragenter', (e) => {
      if (e.dataTransfer && !dataDrag && [
        "react-flow__pane", "canvas-border", "starting-choices"
      ].some(
          (i) => e.target.className.includes(i)
      )) {
        setDataDrag(true);
      }
    });
    document.addEventListener('dragleave', (e) => {
      if (e.dataTransfer && ["dropzone"].some(
          (i) => e.target.className.includes(i)
      )) {
        setDataDrag(false);
      }
    });
  }, []);


  return <div className="Canvas">
    <div className={classnames("canvas-border", {dragging: dataDrag})}/>
    <div className={"canvas-buttons"}>
      {datasets.current.length > 0 && <SearchDR
        onClick={() => setShowProjectSearch(!showProjectSearch)}
        className={classnames("search-dr", {activated: showProjectSearch})}
      />}
    </div>
    {dataDrag && <DropArea
      onDrop={onDrop}
    />}
    {datasets.current.length === 0 && <div className={"starting-choices"}>
      <h1 className={"drop-help-text hide-if-small"}>{"Drop a CSV anywhere!"}</h1>,
      <div className={"starting-separator hide-if-small"} />
      <StartFromProject
        initialApiToken={apiToken.current}
        onNewDataset={() => {}}
        onNewDatasetString={ingestDataString}
        onSaveProjectModels={onSaveProjectModels}
        onSaveApiToken={onSaveApiToken}
      />
    </div>}
    {(datasets.current.length > 0 && showProjectSearch) && (
      <div className={"starting-choices"}>
      <StartFromProject
        firstDataset={false}
        initialApiToken={apiToken.current}
        text={"Add a project"}
        onNewDataset={() => {}}
        onNewDatasetString={ingestDataString}
        onSaveProjectModels={onSaveProjectModels}
        onSaveApiToken={onSaveApiToken}
        onComplete={() => setShowProjectSearch(false)}
      />
      </div>
    )}
    {datasets.current.length > 0 && <CanvasArea datasets={datasets.current ?? []} datasetCount={datasetCount} />}
  </div>;
}