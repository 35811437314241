import {useLayoutEffect, useState} from "react";
import {debounce} from 'lodash';
import useAxios from "axios-hooks";
import axios from "axios";

export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  function immediateUpdateSize() {
    setSize([window.innerWidth, window.innerHeight]);
  }
  const updateSize = debounce(immediateUpdateSize, 300);
  useLayoutEffect(() => {
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export function useDataRobotAPI({url, apiToken, method = 'get', ...rest}) {
  return useAxios({
    method,
    url: `https://dr-explore.herokuapp.com/${url}`,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${apiToken}`,
      "Access-Control-Allow-Origin": "*",
      "Target-URL": "https://app.datarobot.com/api/v2/",
      ...rest.headers,
    },
    ...rest,
  });
}

export function getFromDataRobotAPI({url, apiToken}) {
  return axios.get(`https://dr-explore.herokuapp.com/${url}`, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${apiToken}`,
      "Access-Control-Allow-Origin": "*",
      "Target-URL": "https://app.datarobot.com/api/v2/"
    }
  })
}