import React from 'react';
import classnames from "classnames";

import './Node.scss';
import {ReactComponent as CloseNodeButton} from "../SVG/CloseNodeButton.svg";

const DataFactoryNode = ({data}) => {
  return (
    <div className={"node-wrapper"}>
      <div className={"node-dataset-path-wrapper"}>
        <div className={"node-dataset-path"}>{data.path}</div>
      </div>
      <div
        id={data.id}
        className={classnames("node", {factory: true})}
        onClick={data.onClick}
        style={{width: 70 + 10 * data.text.length}}
      >
        <div className={"node-text"}>{`+ ${data.text}`}</div>
        <div className={"pill-counter"}>{data.count}</div>
        <div className={"node-close"} onClick={(e) => {
          data.delete();
          e.stopPropagation();
        }}>
          <CloseNodeButton/>
        </div>
      </div>
    </div>
  )
};

export default DataFactoryNode;