import React from 'react';
import {useWindowSize} from "../Utils/hooks";
import Graph from "../Graph/Graph";

const CanvasArea = ({datasets, datasetCount, children, minHeight = 0, minWidth = 0}) => {
  const [width, height] = useWindowSize();

  return (
    <div style={{width, height}}>
      {
        (width > minWidth && height > minHeight && datasets.length > 0)
        && <Graph datasets={datasets} datasetCount={datasetCount}>{children}</Graph>
      }
    </div>
  );
};

export default CanvasArea;