import React, {useState} from 'react';

import './StartFromProject.scss';

import {ReactComponent as DataRobotLogo} from '../SVG/DataRobotLogo.svg';
import classnames from "classnames";
import {debounce} from "lodash";
import ProjectSearch from "./ProjectSearch";
import {getFromDataRobotAPI} from "../Utils/hooks";
import {
  getDatasetFile,
  getDatasetForProject, getModelCoefficients, getModelFeatureImpact,
  getModelFeaturePredictionExplanations, getModelLiftChart, getModelResiduals,
  getModels
} from "../Utils/endpoints";

const StartFromProject = ({
  onSaveApiToken,
  onNewDataset,
  onNewDatasetString,
  onSaveProjectModels,
  text = "Start from a project",
  firstDataset = true,
  initialApiToken= null,
  onComplete = () => {},
}) => {
  const [apiTokenEntryActive, setApiTokenEntryActive] = useState(false);
  const [apiToken, setApiToken] = useState(initialApiToken);
  const [success, setSuccess] = useState(!!initialApiToken);
  const [ingestingProject, setIngestingProject] = useState(false);
  const [errorIngestingProject, setErrorIngestingProject] = useState(false);

  const onApiTokenButtonClick = () => {
    setApiTokenEntryActive(true);
  }

  const testToken = (apiToken) => {
    getFromDataRobotAPI({url: 'userNotifications', apiToken})
      .then((response) => {
        if (response.status === 200) {
          setSuccess(true);
          onSaveApiToken(apiToken);
        }
      });
  }

  const debouncedTestToken = debounce(testToken, 500);

  const updateTestAndSave = (value) => {
    if (value.length > 50) {
      debouncedTestToken(value);
    }
    setApiToken(value);
  };

  const saveDataset = ({dataString, name}) => {
    onNewDatasetString({dataString, name});
  }

  const onSelectProject = ({projectId, projectName, catalogId}) => {
    setIngestingProject(true);
    const safe = (promise) => {
      promise
      .catch(() => {
          setIngestingProject(false);
          setErrorIngestingProject(true);
        });
    }
    safe(getModels({apiToken, projectId})
      .then((response) => {
        onSaveProjectModels({
          projectId,
          projectName,
          models: response.data.map(({id, icons, modelType}) => ({
            name: modelType,
            getLiftChart: () => getModelLiftChart({
              apiToken, projectId, modelId: id
            }),
            getFeaturePredictionExplanations: () => getModelFeaturePredictionExplanations({
              apiToken, projectId, modelId: id
            }),
            getFeatureImpact: () => getModelFeatureImpact({
              apiToken, projectId, modelId: id
            }),
            getResiduals: () => getModelResiduals({
              apiToken, projectId, modelId: id
            }),
            getCoefficients: () => getModelCoefficients({
              apiToken, projectId, modelId: id
            })
          })),
        });
      }));

    safe(getDatasetForProject({apiToken, catalogId})
      .then((response) => {
        const {datasetId, name} = response.data;
        return getDatasetFile({datasetId, apiToken}).then(
          (fileResponse) => {
            saveDataset({dataString: fileResponse.data, name});
            setIngestingProject(false);
        })
      }));

    onComplete();
  }

  return (
    <div className={classnames("start-from-project", {
      success, "add-project": !firstDataset
    })}>
      <div className={"start-from-project-title"}>
        <h1 className={"start-from-a-project-text"}>{text}</h1>
        <DataRobotLogo />
      </div>
      {errorIngestingProject && <div className={"ingesting-project error"}>
        Error
      </div>}
      {ingestingProject && <div className={"ingesting-project"}>
        Loading
      </div>}
      {(!ingestingProject && success)
        && <ProjectSearch
        apiToken={apiToken}
        onSelectProject={onSelectProject}
      />}
      {!success && <div className={"api-token-wrapper"}>
        <div className={classnames(
          "api-token-button", {"token-input": apiTokenEntryActive}
        )} onClick={onApiTokenButtonClick}>
          {!apiTokenEntryActive ? "+ API Token" : <input
            value={apiToken}
            onBlur={() => {
              if (apiToken.length === 0) {
                setApiTokenEntryActive(false);
              } else {
                updateTestAndSave(apiToken);
              }
            }}
            onChange={(e) => updateTestAndSave(e.target.value)}
            placeholder={"<API Token>"}
            type={"password"}
          />}
        </div>
        <div className={"api-token-link-wrapper"}>
          <a
            target={"_blank"}
            href={"https://app.datarobot.com/account/developer-tools"}
            className={"api-token-link"}>
            Take me to my API Token
          </a>
        </div>
      </div>
      }
    </div>
  );
}

export default StartFromProject;