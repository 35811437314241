import React, {useState} from 'react';

import SearchableList from "../SearchableList/SearchableList";
import Selectable from "../Selectable/Selectable";

import './MultiSelectList.scss';

const ChoosePrimaryFeature = ({onSetPrimaryFeature, selected, children}) => {
  return <div className={"choose-axis-wrapper"}>
    <div className={"choose-primary-feature"} style={{display: "flex"}}>
      <div>{children}</div>
      <Selectable
        selected={selected}
        onSelection={() => {
          onSetPrimaryFeature(!selected);
        }}
        stopPropagate={true}
      />
    </div>
  </div>;
}

const MultiSelectList = ({items, onSelection, selectFirst, searchKey, onPrimaryFeatureSelection, maxHeight = 200,
  primaryFeature = null
}) => {
  const [shownOptions, setShownOptions] = useState([]);
  const [selectionLookup, setSelectionLookup] = useState(selectFirst && items[0] ? {
    [items[0][searchKey]]: true,
  } : {});
  const additional = [{name: "-- All --"}, {name: "-- None --"}];

  return (
    <div className={"multi-select-search nowheel"}>
      <SearchableList
        items={items}
        onSearch={setShownOptions}
        additional={additional}
        searchKey={searchKey}
      />
      <ul className={"multi-select-options"} style={{maxHeight}}>
        {shownOptions.map((item, i) => (
          <div key={`${item}-${i}`} style={{display: "flex", justifyContent: "space-between"}}>
            <Selectable
              showCheckbox={!additional.map((a) => a.name).includes(item[searchKey])}
              selected={!!selectionLookup[item[searchKey]]}
              onSelection={(checked) => {
                let lookup = {};
                if (item[searchKey] === "-- All --") {
                  lookup = Object.fromEntries(items.map((item) => [item[searchKey], true]));
                } else if (item[searchKey] === "-- None --") {
                  lookup = {};
                } else {
                  lookup = {...selectionLookup, [item[searchKey]]: checked};
                }
                setSelectionLookup(lookup);
                onSelection(lookup);
              }}
            >{item.name}</Selectable>
            {/*{item.setPrimaryFeature && <ChoosePrimaryFeature*/}
            {/*  onSetPrimaryFeature={item.setPrimaryFeature}*/}
            {/*  selected={primaryFeature === item.name}*/}
            {/*/>}*/}
          </div>
        ))}
      </ul>
    </div>
  )
};

export default MultiSelectList;