import React, {useEffect, useState} from 'react';
import {useProjects} from "../Utils/endpoints";

import './ProjectSearch.scss';
import SearchableList from "../SharedComponents/SearchableList/SearchableList";
import classnames from "classnames";
import Selectable from "../SharedComponents/Selectable/Selectable";

const ProjectSearch = ({
  apiToken,
  onSelectProject,
  loadingBarsCount = 12,
  loadingBarsLengthMin = 120,
  loadingBarsLengthMax = 300,
}) => {
  let [{response, loading, error}, refetch] = useProjects(
    {apiToken}
  );

  const [onlyAICatalog, setOnlyAICatalog] = useState(false);
  const [allOptions, setAllOptions] = useState([]);
  const [data, setData] = useState([]);
  const [shownOptions, setShownOptions] = useState([]);

  const updateOptions = (newOptions) => {
    setShownOptions(newOptions);
  }

  useEffect(() => {
    if (response !== null) {
      let data = response?.data;
      setData(data);
      setAllOptions(data);
      setShownOptions(data);
    }
  }, [response]);

  useEffect(() => {
    if (!allOptions?.length) return;
    if (onlyAICatalog) {
      setAllOptions(data.filter(({catalogId}) => !!catalogId));
    } else {
      setAllOptions(data);
    }
  }, [onlyAICatalog])

  const getRandomWidth = () => (
    loadingBarsLengthMin + (Math.random() * (loadingBarsLengthMax - loadingBarsLengthMin))
  );

  return <div className={"project-search-wrapper"}>
    <div className={"project-search"}>
    <SearchableList
      items={allOptions}
      onSearch={updateOptions}
      searchKey={"projectName"}
    />
    {error && <div>{error}</div>}
    {!error && <div className={
      classnames("project-search-results-wrapper", {loading})
    }>
      <ul className={"project-search-results"}>
        {loading
          ? Array(loadingBarsCount).fill(0).map((_, i) =>
            <div
              className={classnames("project-search-result", {loading})}
              key={`loading-${i}`} style={{width: getRandomWidth()}}
            />
          )
          :  shownOptions?.length > 0 && shownOptions.map(({id, projectName, catalogId}, i) => (
            <li key={`${projectName}-${i}`} className={"project-search-result"}
                onClick={() => onSelectProject({projectId: id, catalogId, projectName})}>
              <div className={"project-search-result-text"}>{projectName}</div>
              <div className={classnames("project-search-result-in-catalog", {
                'is-in': !!catalogId
              })}>{
                !!catalogId ? "AI Catalog" : "Data Required"
              }</div>
            </li>
        ))}
      </ul>
    </div>}
    </div>
    <Selectable
      onSelection={(checked) => {setOnlyAICatalog(checked)}}
      selected={onlyAICatalog}
    >Show only projects with data in AI Catalog</Selectable>
  </div>
};

export default ProjectSearch;