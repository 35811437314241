import React from 'react';
import classnames from "classnames";

import './Node.scss';

const Node = ({classNames = {}, data}) => {
  return (
    <div id={data.id} className={classnames("node", classNames)}>
      {data.text}
    </div>
  )
};

export default Node;