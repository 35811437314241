import React, {useEffect, useRef, useState} from 'react';
import * as Papa from "papaparse";

import ReactFlow, {
  removeElements,
  addEdge,
  useStoreState,
  ReactFlowProvider, Controls, MiniMap,
} from 'react-flow-renderer';

import Node from '../Node/Node';
import DataFactoryNode from "../Node/DataFactoryNode";
import './ReactFlow.scss';
import FeaturesNode from "../Node/FeaturesNode";
import PlotNode from "../Node/PlotNode";
import {DataFrame} from "danfojs/src/index";
import {debounce} from "lodash";

const nodeTypes = {
  defaultNode: Node,
  dataFactoryNode: DataFactoryNode,
  featuresNode: FeaturesNode,
  plotNode: PlotNode,
};

const Graph = ({datasets, children, datasetCount}) => (
  <ReactFlowProvider>
    <InnerFlow datasets={datasets} datasetCount={datasetCount}>{children}</InnerFlow>
  </ReactFlowProvider>
);

const DEFAULT_ZOOM = 1;

const InnerFlow = ({datasets, children, datasetCount}) => {
  const prevDatasetsLength = useRef(0);
  const [dataQueue, setDataQueue] = useState([]);
  const flowCanvas = useRef(null);
  const [elements, setElements] = useState([]);
  const elementsRef = useRef(elements);
  const [nodesDraggable, setNodesDraggable] = useState(true);
  const [paneMovable, setPaneMovable] = useState(true);
  const datasetLookup = useRef({});
  const nodeDragStartOffset = useRef({});

  const transform = useStoreState((store) => store.transform);
  const actualPos = ({x, y}) => ({
    x: (x - transform[0]) / (transform[2] * DEFAULT_ZOOM),
    y: (y - transform[1]) / (transform[2] * DEFAULT_ZOOM),
  });
  const relativePos = ({nodeId, x, y}) => {
    const position = getNode(nodeId).position;
    const {x: offsetX, y: offsetY} = actualPos({x, y});
    return {
      x: position.x + offsetX,
      y: position.y + offsetY,
    };
  };

  function updateElements(fn) {
    setElements((els) => {
      elementsRef.current = fn(els);
      return elementsRef.current;
    });
  }

  function addNode (node) {
    if (getNode(node.id)) {
      console.log("Node already added...");
      return;
    }
    updateElements((els) => {
      if (els.includes(node)) return els;
      return [...els, node]
    });
  }

  function updateNode (node) {
    const targetData = getNode(node.id).data;
    const sourceData = node.data;
    Object.keys(sourceData).forEach((k) => {
      if (k === "id") return;
      if (typeof sourceData[k] === "object" && sourceData[k] !== null) {
        const source = sourceData[k];
        const target = targetData[k];
        const seen = new Set();
        for (let j in source) {
          target[j] = source[j];
          seen.add(j);
        }
        for (let j in target) {
          if (!seen.has(j)) {
            delete target[k];
          }
        }
      } else {
        targetData[k] = sourceData[k];
      }
    })
    targetData.update();
  }

  function getNode (nodeId) {
    return elementsRef.current.filter(({id}) => id === nodeId)[0];
  }

  const onElementsRemove = (elementsToRemove) =>
    updateElements((els) => removeElements(elementsToRemove, els));

  const onNodeDragStart = (e, node) => {
    const {x, y} = actualPos({x: e.clientX, y: e.clientY});
    nodeDragStartOffset.current = {
      x: node.position.x - x,
      y: node.position.y - y
    };
  };

  const onNodeDragStop = (e, node) => {
    const clientPos = {...actualPos({x: e.clientX, y: e.clientY})};
    getNode(node.id).position = {
      x: clientPos.x + nodeDragStartOffset.current.x,
      y: clientPos.y + nodeDragStartOffset.current.y,
    };
  };

  const removeElementById = (nodeId) => {
    const toRemove = elementsRef.current.filter(({id}) => id === nodeId);
    const sourceEdgeCounts = {};

    // Check for leftover connections
    elementsRef.current.forEach(({source, target}) => {
      if (target != null) {
        sourceEdgeCounts[target] = (sourceEdgeCounts[target] ?? 0);
        if (source === nodeId) {
          sourceEdgeCounts[target] += 1;
        } else {
          sourceEdgeCounts[target] -= 1;
        }
      }
    });
    onElementsRemove([
      ...toRemove,
      ...elementsRef.current.filter(({id}) => sourceEdgeCounts[id] > 0)
    ]);
  };

  const createFeaturesNode = ({x, y, path}) => {
    const newId = `${path}-${elementsRef.current.length + 1}--features`;
    const onPlot = ({plotX, plotY, featureFilter, id, primaryFeature}) => {
      const plot = createPlotNode({
        x: plotX,
        y: plotY,
        path,
        featureFilter,
        primaryFeature,
      });
      if (id) {
        plot.id = id;
        updateNode(plot);
      } else {
        addNode(plot);
      }
      updateElements((els) =>
        addEdge({
          id: `edge-${newId}-${plot.id}`,
          type: 'smoothstep',
          source: newId,
          target: plot.id,
          style: {stroke: '#ff690f', strokeWidth: 6},
        }, els)
      );
      return plot.id;
    };
    return {
      id: newId,
      type: 'featuresNode',
      data: {
        id: newId,
        type: 'featuresNode',
        text: 'Features',
        path,
        getFeatures: () => datasetLookup.current[path].features,
        delete: () => removeElementById(newId),
        onPlot: ({id, featureFilter}) => {
          const {x: plotX, y: plotY} = relativePos({nodeId: id, x: 300, y: 0});
          return onPlot({plotX, plotY, featureFilter});
        },
        onUpdatePlots: (ids, featureFilter, primaryFeature) =>
          ids.map((id) =>
            onPlot({
              ...getNode(id).position,
              featureFilter,
              id,
              primaryFeature,
            })
          ),
      },
      position: { x, y },
    };
  };

  const createDataFactoryNode = ({x, y, count, path, label}) => {
    const newId = `${label}-${path}-${elementsRef.current.length + 1}--factory`;
    return {
      id: newId,
      type: 'dataFactoryNode',
      data: {
        id: newId,
        type: 'dataFactoryNode',
        text: label,
        path,
        onClick: (e) => {
          if (datasetLookup.current[path].complete) {
            addNode(
              createFeaturesNode({
                ...relativePos({nodeId: newId, x: -50, y: 100}),
                path
              })
            )
          }
        },
        delete: () => removeElementById(newId),
        count,
      },
      position: { x, y },
    };
  };

  const createPlotNode = ({x, y, path, featureFilter, primaryFeature}) => {
    const newId = `${path}-${elementsRef.current.length + 1}--plot`;
    return {
      id: newId,
      type: 'plotNode',
      data: {
        id: newId,
        type: 'plotNode',
        getDataset: () => datasetLookup.current[path].df,
        featureFilter,
        setNodesDraggable,
        primaryFeature,
      },
      position: { x, y },
    };
  }

  const createFilterNode = ({x, y}) => {
    const newId = `${elementsRef.current.length + 1}--filter`;
    return {
      id: newId,
      type: 'filterNode',
      data: {
        id: newId,
        type: 'filterNode',
      },
      position: { x, y },
    };
  }

  const onConnect = (params) => setElements((els) =>
    addEdge({
      ...params,
      type: 'smoothstep',
      style: {stroke: '#ff690f', strokeWidth: 6},
      borderRadius: 100,
    }, els)
  );

  const onLoad = (reactFlowInstance) => {
    if (!reactFlowInstance) return;
    flowCanvas.current = reactFlowInstance;
    reactFlowInstance.fitView();
  };

  function updateWithData(dataItem, {stepCallback, completeCallback} = {}) {
    console.log("Parsing...");
    Papa.parse(
      dataItem.contents,
      {
        fastMode: true,
        worker: true,
        // dynamicTyping: true,
        complete: (results) => {
          datasetLookup.current[dataItem.path].features = results.data[0];
          datasetLookup.current[dataItem.path].nodes.forEach(({id}) => {
            const dom = document.getElementById(id);
            if (dom) {
              dom.querySelector(".pill-counter").innerHTML = (
                datasetLookup.current[dataItem.path].features.length
              );
            }
          });
          datasetLookup.current[dataItem.path].count = (
            datasetLookup.current[dataItem.path]?.count ?? 0
          ) + 1
          datasetLookup.current[dataItem.path].data = results.data.slice(1, 5000);
          console.log("Parsing complete!");
          console.log("Creating DataFrame");
          const df = new DataFrame(
            datasetLookup.current[dataItem.path].data,
            {columns: datasetLookup.current[dataItem.path].features}
          );
          df.fillna({values: 0, inplace: true});
          datasetLookup.current[dataItem.path] = {
            ...datasetLookup.current[dataItem.path],
            complete: true,
            df,
          };
          if (completeCallback) {
            completeCallback();
          }
        }});
  }

  function updateDataset(path, {callback} = {}) {
    const {reference, nodes, complete} = datasetLookup.current[path];
    if (!reference.contents) return;
    if (complete) return;
    updateWithData(reference, {stepCallback: () => {
        nodes.forEach((node) => {
          node.data.count = datasetLookup.current[path].features.length;
          updateNode(node)
          console.log("Tried to update node");
        });
    }, completeCallback: () => {
      if (callback) {
        callback();
      }
    }});
  }

  useEffect(() => {
    if (datasets.length > 0) {
      datasets.forEach((dataItem, i) => {
        if (i < prevDatasetsLength.current) return;
        const {x, y} = actualPos({x: dataItem.x, y: dataItem.y});
        const node = createDataFactoryNode({
          x, y, path: dataItem.path, count: "?", label: dataItem.type
        });
        const nodes = datasetLookup.current[dataItem.path]?.nodes ?? [];
        nodes.push(node);
        datasetLookup.current[dataItem.path] = {
          reference: dataItem, nodes
        };
        if (dataItem.contents) {
          updateDataset(dataItem.path, {
            callback: () => {
              node.data.count = datasetLookup.current[dataItem.path].features.length;
              addNode(node);
            }
          });
        } else {
          addNode(node);
          dataItem.load = () => updateDataset(dataItem.path);
        }
      });
      prevDatasetsLength.current = datasets.length;
    }
  }, [datasetCount, datasets]);

  return (
    <ReactFlow
      elements={elements}
      onElementsRemove={() => {}}
      onConnect={onConnect}
      onNodeDragStart={onNodeDragStart}
      onNodeDragStop={onNodeDragStop}
      onLoad={onLoad}
      snapToGrid={true}
      snapGrid={[12, 12]}
      nodeTypes={nodeTypes}
      nodesDraggable={nodesDraggable}
      paneMoveable={paneMovable}
      deleteKeyCode={undefined}
      defaultZoom={DEFAULT_ZOOM}
      onDrag={(e) => {e.preventDefault(); return false;}}
    >
      {children}
      <MiniMap
        nodeStrokeColor={(n) => {
          if (n.type === 'featuresNode') return '#ff690f';
          if (n.type === 'dataFactoryNode') return '#fff';

          return '#fff';
        }}
        nodeColor={(n) => {
          if (n.type === 'featuresNode') return '#292929';
          if (n.type === 'dataFactoryNode') return '#292929';
          return '#292929';
        }}
        nodeBorderRadius={2}
        style={{backgroundColor: "#292929"}}
        maskColor={"#fffff"}
      />
      <Controls />
    </ReactFlow>
  );
}

export default Graph;