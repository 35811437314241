import React, {useEffect, useRef, useState} from 'react';
import classnames from "classnames";

import './Node.scss';
import {ReactComponent as PlotHandleButton} from '../SVG/PlotHandleButton.svg';
import {ReactComponent as PlotHandleStem} from '../SVG/PlotHandleStem.svg';
import {ReactComponent as CloseNodeButton} from '../SVG/CloseNodeButton.svg';
import {ReactComponent as RoundedStem} from '../SVG/RoundedStem.svg';
import {ReactComponent as InputHandle} from '../SVG/InputHandle.svg';
import {ReactComponent as ShortStem} from '../SVG/ShortStem.svg';
import {ReactComponent as InputHandleCircle} from '../SVG/InputHandleCircle.svg';
import MultiSelectList from "../SharedComponents/MultiSelectList/MultiSelectList";
import {Handle} from "react-flow-renderer";
import Selectable from "../SharedComponents/Selectable/Selectable";

const Checkbox = props => <input type="checkbox" {...props} />;

const FeaturesNode = ({data}) => {
  const featureOptions = useRef([]);
  const [outputConnected, setOutputConnected] = useState(false);
  const [inputs, setInputs] = useState(false);
  const connectedElements = useRef([]);
  const selectionLookup = useRef({});
  const [primaryFeature, setPrimaryFeature] = useState(null);
  const [plotButtonHidden, setPlotButtonHidden] = useState(false);

  const prepareFeatures = (features) => features.map((feature) => ({
      name: feature, setPrimaryFeature: (featureSelected) => {
        if (featureSelected) {
          setPrimaryFeature(feature);
        } else {
          setPrimaryFeature(null);
        }
      },
    }));

  useEffect(() => {
    if (data) {
      featureOptions.current = prepareFeatures(data.getFeatures());
    }
    if (inputs) {
      inputs.forEach((input) => {
        featureOptions.current.push(prepareFeatures(input.data.getFeatures()))
      })
    }
  }, [data, inputs]);

  const onSelection = (newSelectionLookup) => {
    selectionLookup.current = {...newSelectionLookup};
    if (connectedElements.current.length) {
      connectedElements.current = data.onUpdatePlots(
        connectedElements.current, selectionLookup.current, primaryFeature
      );
    }
  };

  useEffect(() => {
    if (connectedElements.current.length) {
      connectedElements.current = data.onUpdatePlots(
        connectedElements.current, selectionLookup.current, primaryFeature
      );
    }
  }, [primaryFeature]);

  const showPlotButtonOnMouseUp = () => {
    setPlotButtonHidden(false);
  };

  useEffect(() => {
    if (plotButtonHidden) {
      document.addEventListener('mouseup', showPlotButtonOnMouseUp);
    } else {
      document.removeEventListener('mouseup', showPlotButtonOnMouseUp);
    }
  }, [plotButtonHidden]);

  const onDragHandle = () => {
    if (!plotButtonHidden) {
      setPlotButtonHidden(true);
    }
  }

  const onClose = (e) => data.delete();

  return (
    <div className={"node-feature-outer-wrapper"}>
      <div className={"node-feature-dataset"}>{data.path}</div>
      <div className={"node-feature-wrapper"}>
        <div className={classnames("node", "feature")}>
          <div className={"node-close"} onClick={onClose}>
            <CloseNodeButton/>
          </div>
          <MultiSelectList
            selectFirst={true}
            items={featureOptions.current}
            onSelection={onSelection}
            searchKey={"name"}
            primaryFeature={primaryFeature}
          />
        </div>
        <div className={"plot-handle"}>
          <Handle
            className={"features-flow-handle"}
            type="source"
            position="right"
            onConnect={() => {setOutputConnected(true)}}
            onMouseDownCapture={onDragHandle}
          />
          {(plotButtonHidden || outputConnected)
            ? <RoundedStem className={"flat-flow-handle"}/>
            : (
              <>
                <PlotHandleStem/>
                <PlotHandleButton className={"plot-handle-button"} onClick={() => {
                  connectedElements.current.push(data.onPlot({
                    id: data.id, featureFilter: selectionLookup.current
                  }));
                  setOutputConnected(true);
                }}/>
              </>
            )
          }
          </div>
      </div>
    </div>
  )
};

export default FeaturesNode;