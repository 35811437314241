import React from 'react';
import classnames from "classnames";

import './Selectable.scss';

export default function Selectable({
  children,
  selected,
  onSelection = () => {},
  showCheckbox = true,
  stopPropagate = false,
  ...props
}) {
  const onChange = (checked) => {
    onSelection(checked);
  };

  return <li
    className={"selectable-option"}
    onClick={(e) => {
      onChange(!selected)
      if (stopPropagate) {
        e.stopPropagation();
      }
    }}
    {...props}
  >
    {showCheckbox && <div className={classnames("selectable-checkbox", {
      checked: selected
    })}/>}
    <div className={
      classnames("selectable-option-label", {
        checked: selected,
      })
    }>
      {children}
    </div>
  </li>;
}