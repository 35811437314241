import React, {useEffect, useRef} from 'react';
import {ReactComponent as SearchIcon} from '../../SVG/SearchIcon.svg';

import './SearchableList.scss';

class SearchStore {
  constructor(items, key, additional = []) {
    this.key = key;
    this.items = additional.concat(items ?? []);
    this.itemLookup = Object.fromEntries(
      this.items.map((a) => this.key ? [a[this.key], a] : [a, a])
    );
    let toSearch = this.key ? this.items.map((a) => a[this.key]) : this.items;
    this.joinedItems = toSearch.join("\n");
  }

  search(query) {
    if (query === "") {
      return this.items;
    }
    const matches = this.joinedItems.matchAll(new RegExp(`.*${query}.*`, 'gi'));
    const results = [...matches].filter((m) => !!m.length);
    return results.map((r) => this.itemLookup[r]);
  }
}

export default function SearchableList ({items, onSearch, searchKey, additional = []}) {
  const searchStore = useRef(null);
  const lastSearch = useRef("");

  useEffect(() => {
    if (items != null) {
      searchStore.current = new SearchStore(items, searchKey, additional);
      onSearch(searchStore.current.search(lastSearch.current));
    }
  }, [items]);

  return (
    <div className={"search-bar-wrapper"}>
      <SearchIcon style={{height: '16px', paddingRight: '5px'}}/>
      <input
        className={"search-bar"}
        onChange={
          (e) => {
            lastSearch.current = e.target.value;
            return onSearch(searchStore.current.search(lastSearch.current))
          }
        }
      />
    </div>
  );
}